




/* .header {
  background-image: url("/images/park details/park details.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */
.header b {
  font-size: var(--bold-font-size);
  font-family: "Gabarito Semibold";
  color: var(--white-color);
  opacity: var(--opacity);
}
.header p {
  font-family: "sethu bold";
  color: var(--white-color);
  font-size: var(--small-primary-font-size);
  opacity: var(--opacity);
}
.title{
  font-family: "ls-semibold" !important;
}
.description{
  font-size: 14px;
  font-family: 'ls-medium';
}
.cardsss{
  max-width: 80%;
  font-family: 'ls-medium';
}
.mediumfonts{

}