.brandName{
    font-size: 33px;
    font-weight: bold;
    color:#ffff
}

.nav-header{
    background-color: #800080;
  
}
.nav-link{
    color:black !important;
    font-size: 16px !important;
}