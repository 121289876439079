.Navbar{
    background-color: #800080;
}

.brandname{
  font-family: 'ls-semibold';
    font-size: 25px;
    color: #FFFFFF; 
}

.nav-item {  
  font-family: 'ls-medium';
    font-size: 15px;
    text-align: left;
    line-height: 28px;
    color: #FFFFFF !important;
    opacity: 1 !important;
}

.nav-item{    
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #131318;
    opacity: 1;
    cursor: pointer;
}

/* .nav-item:hover {
    background-color: #E7F3FF;
}
.nav-item:focus {
    background-color: #E7F3FF;
} */


@media screen and (min-width: 992px) {
    .navborder {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 0 !important;
      transition: all .4s ease;
      display: block !important;
      border: none !important;
      overflow: hidden;
      margin: 0 !important;
      height: 0;
    }
  
    .navborder.show {
      height: 200px;
      margin-top: 45px !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    .navborder {
      margin-top: 15px !important;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: none !important;
    }
  }
 